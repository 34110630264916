import _browser_local_storage_key_store from "./browser_local_storage_key_store";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrowserLocalStorageKeyStore = void 0;
var browser_local_storage_key_store_1 = _browser_local_storage_key_store;
Object.defineProperty(exports, "BrowserLocalStorageKeyStore", {
  enumerable: true,
  get: function () {
    return browser_local_storage_key_store_1.BrowserLocalStorageKeyStore;
  }
});
export default exports;
export const __esModule = exports.__esModule,
  BrowserLocalStorageKeyStore = exports.BrowserLocalStorageKeyStore;